import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
import { connect } from "react-redux" 
//i18n
import { withTranslation } from "react-i18next"
import AuthorizationChecker from "security/AuthorizationChecker"

const SidebarContent = (props) => {

  const ref = useRef()
  const localLanguage = localStorage.getItem("I18N_LANGUAGE")
  const currentLanguage = localLanguage?localLanguage:'en';
  // Menu builder
  const menuBuilder = (menuItems, index = null,currentLanguage) => {
    return (
      ( ( menuItems.roles == undefined ) || ( menuItems.roles.length == 0 ) || ( menuItems.roles!=undefined && AuthorizationChecker.isGranted(menuItems.roles) ) )
      && 
      (
        <li key={index}>
          <Link to={menuItems?.link ? menuItems?.link : '#'} className={menuItems.className}>
            {
              menuItems.icon && (
                <i className={menuItems.icon}></i>
              )
            }
            <span>{menuItems?.label[currentLanguage]}</span>
          </Link>
          {
            menuItems.items && (
              <ul  className={"mm-collapse"}>
                {
                  menuItems.items?.map(function (item, idx) {
                    return menuBuilder(item, idx,currentLanguage);
                  })
                }
              </ul>
            )
          }
        </li>
      )
    )
  }

  // Build menu for each menu tree
  const fullMenu = props.menuItems[0].content?.map(function (item, index) {
    return menuBuilder(item, index,currentLanguage);
  })

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = "/"+location.pathname.split("/")[1]
    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")

      if(items.length==0){
        setTimeout(() => {
          initMenu()
        }, 500);
        return
      }
      /*ul.querySelectorAll("ul")
    .forEach(el => el.textContent.trim() === "" && el.parentNode.removeChild(el))*/
      let flag = true;
      for (let item of items) {
        if (pathName === item.pathname) {
          matchingMenuItem = item
          const brothers = item.parentElement.childNodes
          flag = false;
          if(brothers.length >1){
            const subItems = brothers[0].getElementsByTagName("a")
            for(let subItem of subItems){
              if(pathName==subItem.pathName){
                flag = true
                break
              }
            }
          }else{
            flag = true
          }

          if(flag){
            break
          }

        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem,pathName)
      }
    }

    initMenu()

  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })



  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item, path) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
      for(let child of parent2El.childNodes){
       if(child.childNodes[0].pathname == path){
        child.childNodes[0].classList.add("mm-active")
        break
       }
      }
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false
    }
    scrollElement(item);
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">


            {fullMenu}

          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  menuItems: PropTypes.array,
  layout: PropTypes.object,
  t: PropTypes.any,
}

const mapStatetoProps = (state) => {
  return {
    layout: state.Layout,
    menuItems: state.Menu.menuItems,
  };
};

//export default withRouter(withTranslation()(SidebarContent))

export default connect(mapStatetoProps)(withRouter(withTranslation()(SidebarContent)))