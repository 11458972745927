import React from "react";
import MetaTags from 'react-meta-tags';
import {withRouter, Link, useHistory} from "react-router-dom";
import {Col, Container, Row} from "reactstrap";
import {propTypes} from "react-bootstrap-editable";
import CarouselPage from "../Authentication/CarouselPage";
import {PARAMETERS_API_URL, USERS_API_URL, USERS_COMPANY_API_URL, COMPANY_API_URL} from "../../helpers/api-urls";
import {RegisterComponent} from "zd-react-authentication";
import {CopyrightComponent} from "zd-skin";

const RegisterPage = props => {

    const {history} = props;

    const showLogin=true

    const handleSuccessLogin = (u) => {
        history.push('/auth-email-verification');
    }

    return (
        <React.Fragment>
            <div>
                <MetaTags>
                    <title>Register | Skote </title>
                </MetaTags>
                <Container fluid className="p-0">
                    <Row className="g-0">
                        <CarouselPage/>
                        <Col xl={4}>
                            <div className="auth-full-page-content p-md-5 p-4">
                                <div className="w-100">
                                    <div className="d-flex flex-column h-100">
                                        {/** By default use initials field */}
                                        <RegisterComponent 
                                            apiUrl={USERS_API_URL} 
                                            apiCompanyUrl={COMPANY_API_URL}
                                            apiUserCompanyUrl={USERS_COMPANY_API_URL}
                                            onRegisterSuccess={(u)=>{handleSuccessLogin(u)}} 
                                            useInitials={false}
                                        />
                                        {
                                            showLogin && (
                                                <div className="text-center">
                                                    <p>
                                                        Vous avez déjà un compte ? {" "}
                                                        <Link to="/login"
                                                            className="font-weight-medium text-primary">
                                                            {" "}
                                                            Me connecter
                                                        </Link>{" "}
                                                    </p>
                                                </div>
                                            )
                                        }
                                        <div className="text-center">
                                            <CopyrightComponent
                                                apiUrl={PARAMETERS_API_URL}
                                                code={"DEFAULT"}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default RegisterPage;