import React from "react";
import MetaTags from 'react-meta-tags';
import {PermissionListComponent} from "zd-react-authentication";
import {PERMISSIONS_API_URL} from "../../helpers/api-urls";
import { Link } from "react-router-dom";
import AuthorizationChecker from "security/AuthorizationChecker";

class PagesPermissions extends React.Component {
    /**
     *
     * @param props
     */
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className="page-content">
                <MetaTags>
                    <title>Permission</title>
                </MetaTags>
                    
                <PermissionListComponent 
                    apiUrl={PERMISSIONS_API_URL}
                    addUrl="/pages-permissions/add"
                    path="/pages-permissions"
                    Link={Link}
                    showEdit = {AuthorizationChecker.isGranted(["EDIT_PERMISSIONS"])} 
                    showView = {AuthorizationChecker.isGranted(["READ_PERMISSIONS"])} 
                    showDelete = {AuthorizationChecker.isGranted(["DELETE_PERMISSIONS"])} 
                    showAdd = {AuthorizationChecker.isGranted(["ADD_PERMISSIONS"])} 
                />
            </div>
        )
    }
}

export default PagesPermissions
