import React from "react";
import MetaTags from 'react-meta-tags';
import {ReferenceListComponent} from "zd-react-references";
import {REFERENCES_API_URL} from "../../helpers/api-urls";
import { Link } from "react-router-dom";
import AuthorizationChecker from "security/AuthorizationChecker";

class PagesReferences extends React.Component {
    /**
     *
     * @param props
     */
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className="page-content">
                <MetaTags>
                    <title>Reference</title>
                </MetaTags>                    
                <ReferenceListComponent 
                    apiUrl={REFERENCES_API_URL}
                    addUrl="/pages-references/add"
                    path="/pages-references"
                    basePath ="/pages-references/"
                    Link={Link}
                    showEdit = {AuthorizationChecker.isGranted(["EDIT_REFERENCES"])} 
                    showView = {AuthorizationChecker.isGranted(["READ_REFERENCES"])} 
                    showDelete = {false} 
                    showAdd = {AuthorizationChecker.isGranted(["ADD_REFERENCES"])} 
                />
            </div>
        )
    }
}

export default PagesReferences
