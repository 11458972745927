import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledDropdown,
  Input,
  FormFeedback,
  Label,
  Form,
} from "reactstrap";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import DeleteModal from "../../components/Common/DeleteModal";

import GroupRepository from "./Repository/group-repository";

import AuthorizationChecker from "security/AuthorizationChecker";


// TODO: Sort remotely : https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Remote&selectedStory=Remote%20Sort&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
// TODO: loading spinner :: <Spinner type="grow" size="sm" color="success" /> : /ui-toasts

const Groups = props => {

  const {history} = props;


  const [groups, setGroups] = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [group, setGroup] = useState(null);

  const [searchText, setSearchText] = useState('')

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: group ? group.id : null,
      name: group ? group.name :  '',
      description: group ? group.description : '',
      creation_date : group ? group.creation_date : ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Enter Name"),
      description: Yup.string().required("Enter Description")
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateGroup = {
          id : values.id,
          name: values.name,
          description: values.description,
          creation_date: values.creation_date
        };
        
        GroupRepository.edit(updateGroup).then(item => {
          findAll()
        }).catch(response => {
            // TODO: Catch error
        }).finally(()=>{
          validation.resetForm();
        })
      } else {
        const newGroup = {
          name: values.name,
          description: values.description,
          creation_date: new Date()
        };
        
        GroupRepository.add(newGroup).then(item => {
          findAll()
        }).catch(response => {
          // TODO: Catch error
        }).finally(()=>{
          validation.resetForm();
        })
      }
      toggle();
    },
  });

  //pagination customization
  const [pageOptions, setPageOptions] = useState({
    sizePerPage: 10,
    totalSize: 0,
    custom: true,
  });

  const GroupsColumns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
    },
    {
      dataField: "menu",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, group) => (
        <UncontrolledDropdown direction="left">
          <DropdownToggle href="#" className="card-drop" tag="i">
            <i className="mdi mdi-dots-horizontal font-size-18" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            {
                AuthorizationChecker.isGranted(["EDIT_GROUPS"]) && (
                  <DropdownItem href="#" onClick={() => handleGroupClick(group)} >
                    <i className="mdi mdi-account-edit text-success me-2" />  Edit
                  </DropdownItem>
                )
            }

            {
                AuthorizationChecker.isGranted(["EDIT_GROUPS"]) && (
                  <DropdownItem href="#" onClick={() => associatedContats(group)} >
                    <i className="mdi mdi-account-settings text-success me-2" />  Associated contacts
                  </DropdownItem>
                )
            }

            {
                AuthorizationChecker.isGranted(["DELETE_GROUPS"]) && (
                  <DropdownItem href="#" onClick={() => onClickDelete(group)} >
                    <i className="mdi mdi-account-multiple-minus text-danger me-2" /> Delete
                  </DropdownItem>
                )
            }
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    },
  ];

  const toggle = () => {
    if (modal) {
      setModal(false);
      setGroup(null);
    } else {
      setModal(true);
    }
  };


  const handleGroupClick = arg => {
    setGroup({
      id : arg.id,
      name: arg.name,
      description: arg.description,
      creation_date: arg.creation_date
    });

    setIsEdit(true);
    toggle();
  };

  const associatedContats = group =>{
    history.push("/groups/"+group.id + "/") ;
  }

  var node = useRef();

  //delete group
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (group) => {
    setGroup(group);
    setDeleteModal(true);
  };

  const handleDeleteGroup = () => {
    GroupRepository.delete(group.id).then(()=>{
      setDeleteModal(false);
      findAll(1,searchText)
      // TODO: Catch error
    })
  };

  const { SearchBar } = Search;

  useEffect(() => {
    findAll(1, searchText)
  }, []);

  const findAll = (page=1, search='', sizePerPage = 10) =>{
    GroupRepository.findAll(page, search, sizePerPage).then(data => {
      setGroups(data.items)
      setPageOptions({
        sizePerPage: sizePerPage,
        totalSize: data.totalCount,
        custom: true,
      }) 
    }).catch(response => { 
      // TODO: Catch error
    });
  }
  
  const handleTableChange = (type, { page, sizePerPage }) => {
    findAll(page, searchText, sizePerPage)
  };

  const handleGroupClicks = () => {
    setIsEdit(false);
    toggle();
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteGroup}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <MetaTags>
          <title>Groups | Wadispo</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Wadispo" breadcrumbItem="Groups" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col sm="4">
                      <div className="search-box ms-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <SearchBar searchText={searchText} onSearch={(search) => {setSearchText(search); findAll(node.current.props.pagination.options.page, search)}}/>
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                    <Col sm="8">
                      <div className="text-sm-end">
                        {
                          AuthorizationChecker.isGranted(["ADD_GROUPS"]) && (
                            <Button type="button" color="success" className="btn-rounded  mb-2 me-2" onClick={handleGroupClicks} >
                              <i className="mdi mdi-account-multiple-plus me-2" /> New group
                            </Button>
                          )
                        }
                       
                      </div>
                    </Col>
                  </Row>

                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={GroupsColumns}
                    data={groups}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={groups}
                        columns={GroupsColumns}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    responsive
                                    remote
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    keyField="phonr_number"
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    ref={node}
                                    onTableChange={ handleTableChange }
                                  />
                                </div>
                                <Modal isOpen={modal} toggle={toggle}>
                                  <ModalHeader toggle={toggle} tag="h4">
                                    {!!isEdit
                                      ? "Edit group"
                                      : "Add group"}
                                  </ModalHeader>
                                  <ModalBody>
                                    <Form
                                      onSubmit={(e) => {
                                        e.preventDefault();
                                        validation.handleSubmit();
                                      }}
                                    >
                                      <Row>
                                        <Col className="col-12">
                                          <div className="mb-3">
                                            <Label className="form-label">Name</Label>
                                            <Input
                                              name="name"
                                              type="text"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={validation.values.name || ""}
                                              invalid={
                                                validation.touched.name && validation.errors.name ? true : false
                                              }
                                            />
                                            {validation.touched.name && validation.errors.name ? (
                                              <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                            ) : null}
                                          </div>

                                          <div className="mb-3">
                                            <Label className="form-label">Description</Label>
                                            <Input
                                              name="description"
                                              type="textarea"
                                              rows="3"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={validation.values.description || ""}
                                              invalid={
                                                validation.touched.description && validation.errors.description ? true : false
                                              }
                                            />
                                            {validation.touched.description && validation.errors.description ? (
                                              <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                                            ) : null}
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <div className="text-end">
                                            <button type="submit" className="btn btn-success save-group">
                                              <i className="mdi mdi-account-multiple-check me-2" />
                                                Save
                                            </button>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Form>
                                  </ModalBody>
                                </Modal>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Groups;
