import React from "react";
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import {ReferenceLabelViewComponent} from "zd-react-references";
import {REFERENCE_LABELS_API_URL} from "../../helpers/api-urls";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import AuthorizationChecker from "security/AuthorizationChecker";

const PagesDetailReferenceLabels = (props) =>{
    const {referenceId,referenceValueId, referenceLabelId} = props;
    const baseUrl = "/pages-references/"+referenceId+"/"+referenceValueId
    return ( 
        <>
            <div className="page-content">
                <MetaTags>
                    <title>Reference Label</title>
                </MetaTags>
                
                <div className="mb-4">
                    <Breadcrumbs title="Reference Label" breadcrumbItem={"Reference Label - Détails"}/>
                    <Link className="btn btn-info" to={baseUrl}>Revoir la liste</Link>

                    { AuthorizationChecker.isGranted(["EDIT_REFERENCE_LABELS"]) && ( <Link className="btn btn-info ms-3" to={baseUrl+"/"+referenceLabelId+"/edit"}>Modifier</Link> )}
                    
                    { AuthorizationChecker.isGranted(["ADD_REFERENCE_LABELS"]) && (<Link className="btn btn-info ms-3" to={baseUrl+"/add"}>Ajouter</Link> )}
                </div>
                <ReferenceLabelViewComponent
                    referenceLabelId={referenceLabelId}
                    referenceValueId={referenceValueId}
                    referenceId={referenceId}
                    apiUrl={REFERENCE_LABELS_API_URL}
                    addUrl={baseUrl+"/add"}
                    path={baseUrl}
                />
            </div>
        </>
    );
}

export default PagesDetailReferenceLabels
