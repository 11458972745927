import React from "react";
import {Link, useParams} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import {RoleViewComponent} from "zd-react-authentication";
import {ROLES_API_URL, ROLES_PERMISSIONS_API_URL} from "../../helpers/api-urls";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import AuthorizationChecker from "security/AuthorizationChecker";

const PagesDetailRoles = (props) =>{
    const params = useParams();
    const {id = 0} = params;

    return ( 
        <>
            <div className="page-content">
                <MetaTags>
                    <title>Role</title>
                </MetaTags>
                
                <div className="mb-4">
                    <Breadcrumbs title="Role" breadcrumbItem={"Role - Détails"}/>
                    <Link className="btn btn-info" to={"/pages-roles"}>Revoir la liste</Link>

                    { AuthorizationChecker.isGranted(["EDIT_ROLES"]) && ( <Link className="btn btn-info ms-3" to={"/pages-roles/"+id+"/edit"}>Modifier</Link> )}
                    { AuthorizationChecker.isGranted(["ADD_ROLES"]) && ( <Link className="btn btn-info ms-3" to={"/pages-roles/add"}>Ajouter</Link> )}
                </div>
                <RoleViewComponent 
                    id={id}
                    apiUrl={ROLES_API_URL}
                    permissionPath="/pages-permissions"
                    apiRolePermissionUrl={ROLES_PERMISSIONS_API_URL}
                    showAssociatedPermissionsEdit = { AuthorizationChecker.isGranted(["EDIT_PERMISSIONS"])}
                    showAssociatedPermissionsView = { AuthorizationChecker.isGranted(["READ_PERMISSIONS"])}
                    showAssociatedPermissionsDelete  = { AuthorizationChecker.isGranted(["DELETE_PERMISSIONS"])}
                    onDelete={(event, elt) => {}}
                    Link={Link}
                />
            </div>
        </>
    );
}

export default PagesDetailRoles
