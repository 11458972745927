import axios from "axios";
import {HTTP_RESPONSE} from "../../Utility/Helpers/constants";
import UserProvier from "../../../security/UserProvier";
UserProvier.setToken();

export const DATA_API_URL = process.env.REACT_APP_API_BASE_URL + 'group-contacts';
export const DATA_API_GROUP_URL = process.env.REACT_APP_API_BASE_URL + 'groups';

const queries = (groupId, itemPerPage = 10, page = 1, search = '') => {
    let params = {
        where : {
            groupId : groupId
        },
        include : [
            {
                relation: 'contact',
                where: {
                    or : [
                        { phone_number : { like : '%'+search+'%'}},
                        { username : { like : '%'+search+'%'}},
                        { email : { like : '%'+search+'%'}},
                        { address : { like : '%'+search+'%'}},
                        { priority : { like : '%'+search+'%'}},
                    ]
                },
                order : 'id DESC',
            }
        ],
        limit: itemPerPage,
        skip: (((page - 1) * itemPerPage) > 0 ? ((page - 1) * itemPerPage) : 0)
    }
    return JSON.stringify(params)
}

const GroupContactRepository = {
    loadGroup : (groupId) =>{
        let  localItem = window.localStorage.getItem("current_group")

        if(localItem) {
            return new Promise((resolve, reject) => {
                resolve(JSON.parse(localItem))
             })
        }

        return axios.get(DATA_API_GROUP_URL + '/' + groupId).then(response => {
            return response.data;
        }).catch((e) => {
            if (e.response) {
                throw e.response;
            } else {
                throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
            }
        });
    },
    add : (item) => {
        return axios
            .post(DATA_API_URL, item).then(response => {
                return response.data;
            }).catch((e) => {
                if (e.response) {
                    throw e.response;
                } else {
                    throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
                }
            });
    },
    findAll : (groupId, page = 1, search = '', itemPerpage = 10) => {
        return axios
            .get(DATA_API_URL,{
                params:{
                    filter:queries(groupId,itemPerpage, page, search)
                }
            }).then(response => {
                return response.data;
            }).catch((e) => {
                if (e.response) {
                    throw e.response;
                } else {
                    throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
                }
            });
    },
    delete : (item) => {
        return axios.post(DATA_API_URL+'/del', item ).then((response) => {
            return "ok"
        }).catch((e) => {
            if (e.response) {
                throw e.response;
            } else {
                throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
            }
        });
    }
}
export default GroupContactRepository;
