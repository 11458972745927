const FILES_API_URL = process.env.REACT_APP_API_BASE_URL + 'files';
export const SERVER_BASE_URL = process.env.REACT_APP_BASE_URL;
export const USERS_API_URL = process.env.REACT_APP_API_BASE_URL + 'users';
export const TAGS_API_URL = process.env.REACT_APP_API_BASE_URL + 'tags';
export const FILES_SYSTEM_API_URL = process.env.REACT_APP_API_BASE_URL + 'files-system';
export const FILES_SYSTEM_FOLDER_API_URL = process.env.REACT_APP_API_BASE_URL + 'folders';
export const PARAMETERS_API_URL = process.env.REACT_APP_API_BASE_URL + 'parameters';


export const PERMISSIONS_API_URL = process.env.REACT_APP_API_BASE_URL + 'permissions';
export const ROLES_API_URL = process.env.REACT_APP_API_BASE_URL + 'roles';
export const ROLES_PERMISSIONS_API_URL = process.env.REACT_APP_API_BASE_URL + 'role-permissions';
export const COMPANY_API_URL = process.env.REACT_APP_API_BASE_URL + 'companies';

export const USERS_COMPANY_API_URL = process.env.REACT_APP_API_BASE_URL + 'user-companies';
export const USERS_COMPANY_ROLES_API_URL = process.env.REACT_APP_API_BASE_URL + 'user-company-roles';

export default FILES_API_URL;

export const REFERENCES_API_URL = process.env.REACT_APP_API_BASE_URL + 'references';
export const REFERENCE_LABELS_API_URL = process.env.REACT_APP_API_BASE_URL + 'reference-labels';
export const REFERENCE_LANGUES_API_URL = process.env.REACT_APP_API_BASE_URL + 'reference-langues';
export const REFERENCE_VALUES_API_URL = process.env.REACT_APP_API_BASE_URL + 'reference-values';
export const REFERENCE_COMPANIES_API_URL = process.env.REACT_APP_API_BASE_URL + 'reference-companies';



