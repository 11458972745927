/**
 * Helper pour manager les champs select autocomplete des formulaires.
 * Dans une mésure du possible, ce helper peut être modularisé si les méthode prennent du nombre.
 */
import ContactRepository from "../pages/Contacts/Repository/contact-repository";

const Autocomplete = {
    /**
     * Autocomplete sur les contacts
     * @param inputValue
     */
    contacts: (inputValue, exclude=null) => {
        return ContactRepository.findAll( 1,inputValue,10,exclude).then((data) => data.items)
    },
};

export default Autocomplete;
