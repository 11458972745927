import React from "react";
import {Link, useParams} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import {RoleEditComponent} from "zd-react-authentication";
import {ROLES_API_URL, PERMISSIONS_API_URL,ROLES_PERMISSIONS_API_URL} from "../../helpers/api-urls";
import Breadcrumbs from "../../components/Common/Breadcrumb"

const PagesEditRoles  = (props) =>{
    const {history} = props;
    const params = useParams();
    const {id = 0} = params;
    const onEditSuccess = (response) => {
        history.push("/pages-roles");
    }
    return (
        <>
            <div className="page-content">
                <MetaTags>
                    <title>Role</title>
                </MetaTags>

                <div className="mb-4">
                    <Breadcrumbs title="Role" breadcrumbItem={"Role - " + ( id ? "Modification" : "Ajout")}/>
                    <Link className="btn btn-info" to={"/pages-roles"}>Revoir la liste</Link>
                </div>
                <RoleEditComponent
                    id={id}
                    onEditSuccess={onEditSuccess}
                    apiUrl={ROLES_API_URL}
                    apiPermissionUrl={PERMISSIONS_API_URL}
                    apiRolePermissionUrl={ROLES_PERMISSIONS_API_URL}
                />
            </div>
        </>
    )
}

export default PagesEditRoles
