import React from "react";
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import {ReferenceLabelEditComponent} from "zd-react-references";
import {REFERENCE_LABELS_API_URL, REFERENCE_VALUES_API_URL, REFERENCE_LANGUES_API_URL} from "../../helpers/api-urls";
import Breadcrumbs from "../../components/Common/Breadcrumb"

const PagesEditReferenceLabels  = (props) =>{

    const {history, referenceId, referenceValueId,referenceLabelId} = props;
    const baseUrl = "/pages-references/"+referenceId+"/"+referenceValueId
    const onEditSuccess = (response) => {
        history.push(baseUrl);
    }
    return (
        <>
            <div className="page-content">
                <MetaTags>
                    <title>Reference Labels</title>
                </MetaTags>

                <div className="mb-4">
                    <Breadcrumbs title="Reference Label" breadcrumbItem={"Reference Labels - " + ( referenceLabelId ? "Modification" : "Ajout")}/>
                    <Link className="btn btn-info" to={baseUrl}>Revoir la liste</Link>
                </div>
                <ReferenceLabelEditComponent 
                    referenceLabelId={referenceLabelId}
                    referenceValueId={referenceValueId}
                    referenceId={referenceId}
                    onEditSuccess={onEditSuccess}
                    apiUrl={REFERENCE_LABELS_API_URL}
                    apiLangueUrl= {REFERENCE_LANGUES_API_URL}
                    apiReferenceValueUrl= {REFERENCE_VALUES_API_URL}
                    addUrl={baseUrl+"/add"}
                    path={baseUrl}
                />
            </div>
        </>
    )
}

export default PagesEditReferenceLabels
