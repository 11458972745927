import axios from "axios";
import {HTTP_RESPONSE} from "../../Utility/Helpers/constants";
import UserProvier from "../../../security/UserProvier";
UserProvier.setToken();

export const DATA_API_URL = process.env.REACT_APP_API_BASE_URL + 'groups';

const queries = (itemPerPage = 10, page = 1, search = '') => {
    return DATA_API_URL + '?filter[where][or][0][name][like]=%'+search+'%' +
        '&filter[where][or][1][description][like]=%'+search+'%' +
        '&filter[order]=id DESC'+
        '&filter[limit]=' + itemPerPage + '&filter[skip]=' + (((page - 1) * itemPerPage) > 0 ? ((page - 1) * itemPerPage) : 0);
}

const GroupRepository = {
    add : (item) => {
        return axios
            .post(DATA_API_URL, item).then(response => {
                return response.data;
            }).catch((e) => {
                if (e.response) {
                    throw e.response;
                } else {
                    throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
                }
            });
    },
    edit: (item) => {
        return axios
            .put(DATA_API_URL + '/' + item.id, item).then(response => {
                return response.data;
            }).catch((e) => {
                if (e.response) {
                    throw e.response;
                } else {
                    throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
                }
            });
    },
    findAll : (page = 1, search = '', itemPerpage = 10) => {
        return axios
            .get(queries(itemPerpage, page, search))
            .then(response => {
                return response.data;
            }).catch((e) => {
                if (e.response) {
                    throw e.response;
                } else {
                    throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
                }
            });
    },
    findByPhoneNumber : (id) => {
        return axios
            .get(DATA_API_URL + '/' + id)
            .then(response => {
                return response.data;
            }).catch((e) => {
                if (e.response) {
                    throw e.response;
                } else {
                    throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
                }
            });
    },
    delete : (id) => {
        return axios.delete(DATA_API_URL + '/' + id).then((response) => {
            return id
        }).catch((e) => {
            if (e.response) {
                throw e.response;
            } else {
                throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
            }
        });
    }
}
export default GroupRepository;
