import React, {useEffect} from "react"
import {useHistory, useParams} from "react-router-dom";
import {
    Container,
} from "reactstrap"
import MetaTags from 'react-meta-tags';
//Import Breadcrumb
//Import Attachment Cards
import {Link} from "react-router-dom";
import {EditUserComponent} from "zd-react-authentication";
import {USERS_API_URL} from "../../helpers/api-urls";

const PagesUserEdit = () => {
    const history = useHistory();
    const params = useParams();
    const {id = 0} = params;

    useEffect(() => {
    }, []);

    /**
     *  Methode appelée quand un dossier est ajouté
     *
     */
    const getPagesUrl = () => {
        return '/pages-users';
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Utilisateurs - Edition</title>
                </MetaTags>
                <Container fluid={true}>
                    <div className="mb-4">
                        <Link className="btn btn-info" to={getPagesUrl()}>Revoir la liste</Link>
                    </div>
                    <EditUserComponent
                        id={id}
                        onEditSuccess={(u)=>{
                            history.push(getPagesUrl());
                        }}
                        apiUrl={USERS_API_URL}
                    />
                </Container>
            </div>
        </React.Fragment>
    )
}
export default PagesUserEdit
