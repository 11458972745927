import React from "react";
import MetaTags from 'react-meta-tags';
import {withRouter, Link, useHistory} from "react-router-dom";
import {LogoComponent, CopyrightComponent} from 'zd-skin'
import {LoginUserComponent} from 'zd-react-authentication'
import {Col, Container, Row} from "reactstrap";
import {loginSuccess} from "../../store/actions";
import CarouselPage from "./CarouselPage";
import {useDispatch} from "react-redux";
import {propTypes} from "react-bootstrap-editable";
import {PARAMETERS_API_URL, SERVER_BASE_URL, USERS_API_URL, COMPANY_API_URL} from "../../helpers/api-urls";
import logo_zd from "../../assets/images/logo-zd.png";
import UserProvier from "security/UserProvier";

const NewLogin = (props) => {
    
    const history = useHistory();
    const dispatch = useDispatch();

    const showRegister=true;

    const handleSuccessLogin = (u) => {
        localStorage.setItem("authUser", JSON.stringify(u));
        UserProvier.setToken();
        dispatch(loginSuccess(u));
        history.push('/dashboard');
    }

    return (
        <React.Fragment>
            <div>
                <MetaTags>
                    <title>Login | Wadispo</title>
                </MetaTags>
                <Container fluid className="p-0">
                    <Row className="g-0">
                        <CarouselPage/>

                        <Col xl={3}>
                            <div className="auth-full-page-content p-md-5 p-4">
                                <div className="w-100">
                                    <div className="d-flex flex-column h-100">
                                        <div className="mb-4 mb-md-5">
                                            <Link to="/dashboard" className="d-block auth-logo">
                                                <LogoComponent 
                                                    apiUrl={PARAMETERS_API_URL}
                                                    code={"DEFAULT"}
                                                    serverBaseUrl={SERVER_BASE_URL}/>
                                            </Link>
                                        </div>
                                        <div className="my-auto">
                                            <div className="my-auto">
                                                <img src={logo_zd} alt="Logo" width="90%"/>
                                            </div>
                                            <div className="mt-4">
                                                <LoginUserComponent
                                                    apiUrl={USERS_API_URL + '/login'}
                                                    apiCompanyUrl={COMPANY_API_URL}
                                                    onLoginSuccess={(u) => handleSuccessLogin(u)}/>
                                                { showRegister ? (
                                                    <p className="mt-4 text-center">
                                                        {"Vous n'avez pas de compte ?"}
                                                        <Link to="/register"
                                                            className="font-weight-medium text-primary">
                                                            {" "}
                                                            Créer un compte
                                                        </Link>{" "}
                                                    </p>
                                                ) : ""
                                                }
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <CopyrightComponent apiUrl={PARAMETERS_API_URL}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withRouter(NewLogin);

NewLogin.propTypes = {};
