import React from "react";
import MetaTags from 'react-meta-tags';
import {UserCompanyRoleListComponent} from "zd-react-authentication";
import {USERS_COMPANY_ROLES_API_URL, USERS_COMPANY_API_URL} from "../../helpers/api-urls";
import { Link } from "react-router-dom";
import AuthorizationChecker from "security/AuthorizationChecker";

const PagesCompanyUsers = (props) => {

    return (
    <>
        <div className="page-content">
            <MetaTags>
                <title>Company Users</title>
            </MetaTags>
                
            <UserCompanyRoleListComponent
                apiUrl={USERS_COMPANY_API_URL}
                apiUserCompanyRole={USERS_COMPANY_ROLES_API_URL}
                addUrl="/pages-company-users-roles/add"
                path="/pages-company-users-roles"
                Link={Link}
                showAdd = {AuthorizationChecker.isGranted(["ADD_USERS_COMPANY"])} 
                showEdit = {AuthorizationChecker.isGranted(["EDIT_USERS_COMPANY"])} 
                showView = {AuthorizationChecker.isGranted(["READ_USERS_COMPANY"])} 
                showDelete = {AuthorizationChecker.isGranted(["DELETE_USERS_COMPANY"])} 
            />
        </div>
    </>
    )
}

export default PagesCompanyUsers
