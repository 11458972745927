import AccessDecisionManager from "./AccessDecisionManager";
/**
 * AuthorizationChecker
 * Enable to manage user session and acces authorization.
 *
 * @since      2.0.0
 * @package    App
 * @subpackage App\Core\Authorization
 */
const AuthorizationChecker = {
        /**
     * Permet de tester si un utilisateur a un role
     * @param needle
     */
    isGranted:(needle)=> {
        return AccessDecisionManager.isGranted(needle);
    },
}
export default AuthorizationChecker;
