import React from "react";
import MetaTags from 'react-meta-tags';
import {ReferenceValueListComponent} from "zd-react-references";
import {REFERENCE_VALUES_API_URL} from "../../helpers/api-urls";
import { Link } from "react-router-dom";
import AuthorizationChecker from "security/AuthorizationChecker";

class PagesReferenceValues extends React.Component {
    /**
     *
     * @param props
     */
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className="page-content">
                <MetaTags>
                    <title>Reference Value</title>
                </MetaTags>
                    
                <ReferenceValueListComponent 
                    apiUrl={REFERENCE_VALUES_API_URL}
                    addUrl={"/pages-references/"+this.props.referenceId+"/add"}
                    path={"/pages-references/"+this.props.referenceId}
                    basePath={"/pages-references/"+this.props.referenceId+"/"}
                    backPath={"/pages-references/"}
                    Link={Link}
                    referenceId={this.props.referenceId}
                    showEdit = {AuthorizationChecker.isGranted(["EDIT_REFERENCE_VALUES"])} 
                    showView = {AuthorizationChecker.isGranted(["READ_REFERENCE_VALUES"])} 
                    showDelete = {false} 
                    showAdd = {AuthorizationChecker.isGranted(["ADD_REFERENCE_VALUES"])} 
                />
            </div>
        )
    }
}

export default PagesReferenceValues
