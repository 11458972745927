import { all, put } from "redux-saga/effects";
import axios from "axios"
import { getMenuItems } from "store/actions";


function* getMenu() {
    let  data;
    const rest =   yield axios.get(process.env.REACT_APP_API_BASE_URL +'/menus').then(res=> res.data)
        yield put (getMenuItems(rest))
       
}

function* menuSaga() {
    yield all([
        getMenu()
      ])

}

export default menuSaga;
