import React from "react";
import {Link, useParams} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import {PermissionViewComponent} from "zd-react-authentication";
import {PERMISSIONS_API_URL} from "../../helpers/api-urls";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import AuthorizationChecker from "security/AuthorizationChecker";

const PagesDetailPermissions = (props) =>{
    const params = useParams();
    const {id = 0} = params;

    return ( 
        <>
            <div className="page-content">
                <MetaTags>
                    <title>Permission</title>
                </MetaTags>
                
                <div className="mb-4">
                    <Breadcrumbs title="Permission" breadcrumbItem={"Permission - Détails"}/>
                    <Link className="btn btn-info" to={"/pages-permissions"}>Revoir la liste</Link>

                    { AuthorizationChecker.isGranted(["EDIT_PERMISSIONS"]) && ( <Link className="btn btn-info ms-3" to={"/pages-permissions/"+id+"/edit"}>Modifier</Link> )}
                    
                    { AuthorizationChecker.isGranted(["ADD_PERMISSIONS"]) && (<Link className="btn btn-info ms-3" to={"/pages-permissions/add"}>Ajouter</Link> )}
                </div>
                <PermissionViewComponent
                    id={id}
                    apiUrl={PERMISSIONS_API_URL}
                    addUrl="/pages-permissions/add"
                    path="/pages-permissions"
                />
            </div>
        </>
    );
}

export default PagesDetailPermissions
