import React from "react";
import {Link, useParams} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import {CompanyEditComponent} from "zd-react-authentication";
import {COMPANY_API_URL,USERS_COMPANY_API_URL,USERS_API_URL} from "../../helpers/api-urls";
import Breadcrumbs from "../../components/Common/Breadcrumb"

const PagesEditCompanies  = (props) =>{

    const {history} = props;
    const params = useParams(); 
    const {id = 0} = params;
    const onEditSuccess = (response) => {
        history.push("/pages-companies");
    }
    return (
        <>
            <div className="page-content">
                <MetaTags>
                    <title>Company</title>
                </MetaTags>

                <div className="mb-4">
                    <Breadcrumbs title="Company" breadcrumbItem={"Company - " + ( id ? "Modification" : "Ajout")}/>
                    <Link className="btn btn-info" to={"/pages-companies"}>Revoir la liste</Link>
                </div>
                <CompanyEditComponent
                    id={id}
                    onEditSuccess={onEditSuccess}
                    apiUrl={COMPANY_API_URL}
                    apiUserUrl={USERS_API_URL}
                    apiUserCompanyUrl={USERS_COMPANY_API_URL}
                    addUrl="/pages-companies/add"
                    path="/pages-companies"
                />
            </div>
        </>
    )
}

export default PagesEditCompanies
