import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import * as moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledDropdown,
  Input,
  FormFeedback,
  Label,
  Form,
} from "reactstrap";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import DeleteModal from "../../components/Common/DeleteModal";

import ContactRepository from "./Repository/contact-repository";

import AuthorizationChecker from "security/AuthorizationChecker";


// TODO: Sort remotely : https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Remote&selectedStory=Remote%20Sort&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel

// TODO: loading spinner :: <Spinner type="grow" size="sm" color="success" /> : /ui-toasts
const Contacts = props => {
  const [contacts, setContacts] = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [contact, setContact] = useState(null);

  const [searchText, setSearchText] = useState('')

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: contact ? contact.username :  '',
      phone_number: contact ? contact.phone_number :  '',
      email: contact ? contact.email : '',
      address: contact ? contact.address : '',
      priority: contact ? contact.priority : '',
      join_date: contact ? contact.join_date : '',
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your Name"),
      phone_number: Yup.string().required("Please Enter Your Phone"),
      email: Yup.string().required("Please Enter Your Email"),
      address: Yup.string().required("Please Enter Your Address"),
      priority: Yup.number().required("Please Enter Your Priority").max(5, "Must be less than 5").min(0, "Must be greater then 0"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateContact = {
          username: values.username,
          phone_number: values.phone_number,
          email: values.email,
          address: values.address,
          priority: values.priority,
          join_date: values.join_date ? new Date(values.join_date) : new Date(),
        };
        
        ContactRepository.edit(updateContact).then(item => {
          findAll()
        }).catch(response => {
            // TODO: Catch error
        }).finally(()=>{
          validation.resetForm();
        })
      } else {
        const newContact = {
          username: values.username,
          phone_number: values.phone_number,
          email: values.email,
          address: values.address,
          priority: values.priority,
          join_date: values.join_date ? new Date(values.join_date) : new Date(),
        };
        
        ContactRepository.add(newContact).then(item => {
          findAll()
        }).catch(response => {
          // TODO: Catch error
        }).finally(()=>{
          validation.resetForm();
        })
      }
      toggle();
    },
  });

  //pagination customization
  const [pageOptions, setPageOptions] = useState({
    sizePerPage: 10,
    totalSize: 0,
    custom: true,
  });

  const ContactsColumns = [
    {
      dataField: "username",
      text: "Username",
      sort: true,
    },
    {
      text: "Phone No",
      dataField: "phone_number",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <>
          <p className="mb-1">{row.phone_number}</p>
        </>
      ),
    },
    {
      text: "Email",
      dataField: "email",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <>
          <p className="mb-0">{row.email}</p>
        </>
      ),
    },
    {
      dataField: "address",
      text: "Address",
      sort: true,
    },
    {
      dataField: "priority",
      text: "Priority",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Badge color="success" className="bg-success font-size-12">
          { priorityStarlength(row.priority).map((i)=> <i key={i} className="mdi mdi-star me-1" />)}
          {row.priority}
        </Badge>
      ),
    },
    {
      dataField: "join_date",
      text: "Joining Date",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.join_date),
    },
    {
      dataField: "menu",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, contact) => (
        <UncontrolledDropdown direction="left">
          <DropdownToggle href="#" className="card-drop" tag="i">
            <i className="mdi mdi-dots-horizontal font-size-18" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            {
                AuthorizationChecker.isGranted(["EDIT_CONTACTS"]) && (
                  <DropdownItem href="#" onClick={() => handleContactClick(contact)} >
                    <i className="mdi mdi-account-edit text-success me-2" />  Edit
                  </DropdownItem>
                )
            }

            {
                AuthorizationChecker.isGranted(["DELETE_CONTACTS"]) && (
                  <DropdownItem href="#" onClick={() => onClickDelete(contact)} >
                    <i className="mdi mdi-account-minus text-danger me-2" /> Delete
                  </DropdownItem>
                )
            }
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    },
  ];

  const priorityStarlength = (priority)=>{
    let nbStar = Math.round(0.5*priority + 0.5)
    let tmp = []
    while (nbStar){
      tmp.push(nbStar--)
    }

    return tmp
  }

  const toggle = () => {
    if (modal) {
      setModal(false);
      setContact(null);
    } else {
      setModal(true);
    }
  };


  const handleContactClick = arg => {
    setContact({
      username: arg.username,
      phone_number: arg.phone_number,
      email: arg.email,
      address: arg.address,
      priority: arg.priority,
      join_date: arg.join_date.split("T")[0],
    });

    setIsEdit(true);
    toggle();
  };

  var node = useRef();

  //delete contact
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (contact) => {
    setContact(contact);
    setDeleteModal(true);
  };

  const handleDeleteContact = () => {
    ContactRepository.removeByPhoneNumber(contact.phone_number).then(()=>{
      setDeleteModal(false);
      findAll(1,searchText)
      // TODO: Catch error
    })
  };

  const { SearchBar } = Search;

  useEffect(() => {
    findAll(1, searchText)
  }, []);

  const findAll = (page=1, search='', sizePerPage = 10) =>{
    ContactRepository.findAll(page, search, sizePerPage).then(data => {
      setContacts(data.items)
      setPageOptions({
        sizePerPage: sizePerPage,
        totalSize: data.totalCount,
        custom: true,
      }) 
    }).catch(response => { 
      // TODO: Catch error
    });
  }
  
  const handleTableChange = (type, { page, sizePerPage }) => {
    findAll(page, searchText, sizePerPage)
  };

  const handleContactClicks = () => {
    setIsEdit(false);
    toggle();
  };

  const defaultSorted = [
    {
      dataField: "join_date",
      order: "desc",
    },
  ];

  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };
  
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteContact}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <MetaTags>
          <title>Contacts | Wadispo</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Wadispo" breadcrumbItem="Contacts" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col sm="4">
                      <div className="search-box ms-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <SearchBar searchText={searchText} onSearch={(search) => {setSearchText(search); findAll(node.current.props.pagination.options.page, search)}}/>
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                    <Col sm="8">
                      <div className="text-sm-end">
                        {
                          AuthorizationChecker.isGranted(["ADD_CONTACTS"]) && (
                            <Button type="button" color="success" className="btn-rounded  mb-2 me-2" onClick={handleContactClicks} >
                              <i className="mdi mdi-account-plus me-2" /> New contact
                            </Button>
                          )
                        }
                       
                      </div>
                    </Col>
                  </Row>

                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="phone_number"
                    columns={ContactsColumns}
                    data={contacts}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="phone_number"
                        data={contacts}
                        columns={ContactsColumns}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    responsive
                                    remote
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    keyField="phonr_number"
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    ref={node}
                                    onTableChange={ handleTableChange }
                                  />
                                </div>
                                <Modal isOpen={modal} toggle={toggle}>
                                  <ModalHeader toggle={toggle} tag="h4">
                                    {!!isEdit
                                      ? "Edit contact"
                                      : "Add contact"}
                                  </ModalHeader>
                                  <ModalBody>
                                    <Form
                                      onSubmit={(e) => {
                                        e.preventDefault();
                                        validation.handleSubmit();
                                      }}
                                    >
                                      <Row>
                                        <Col className="col-12">
                                          <div className="mb-3">
                                            <Label className="form-label">UserName</Label>
                                            <Input
                                              name="username"
                                              type="text"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={validation.values.username || ""}
                                              invalid={
                                                validation.touched.username && validation.errors.username ? true : false
                                              }
                                            />
                                            {validation.touched.username && validation.errors.username ? (
                                              <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                                            ) : null}
                                          </div>

                                          <div className="mb-3">
                                            <Label className="form-label">Phone No</Label>
                                            <Input
                                              name="phone_number"
                                              type="text"
                                              disabled={isEdit} // TODO: enable this when oreal handle contact-group relation on contact editing
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={validation.values.phone_number  || ""}
                                              invalid={
                                                validation.touched.phone_number && validation.errors.phone_number ? true : false
                                              }
                                            />
                                            {validation.touched.phone_number && validation.errors.phone_number ? (
                                              <FormFeedback type="invalid">{validation.errors.phone_number}</FormFeedback>
                                            ) : null}
                                          </div>

                                          <div className="mb-3">
                                            <Label className="form-label">Email</Label>
                                            <Input
                                              name="email"
                                              type="email"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={validation.values.email ||  ""}
                                              invalid={
                                                validation.touched.email && validation.errors.email ? true : false
                                              }
                                            />
                                            {validation.touched.email && validation.errors.email ? (
                                              <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                            ) : null}
                                          </div>

                                          <div className="mb-3">
                                            <Label className="form-label">Address</Label>
                                            <Input
                                              name="address"
                                              type="textarea"
                                              rows="3"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={validation.values.address || ""}
                                              invalid={
                                                validation.touched.address && validation.errors.address ? true : false
                                              }
                                            />
                                            {validation.touched.address && validation.errors.address ? (
                                              <FormFeedback type="invalid">{validation.errors.address}</FormFeedback>
                                            ) : null}
                                          </div>

                                          <div className="mb-3">
                                            <Label className="form-label">Priority</Label>
                                            <Input
                                              name="priority"
                                              type="number"
                                              max={5}
                                              min={0}
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={validation.values.priority || ""}
                                              invalid={
                                                validation.touched.priority && validation.errors.priority ? true : false
                                              }
                                            />
                                            {validation.touched.priority && validation.errors.priority ? (
                                              <FormFeedback type="invalid">{validation.errors.priority}</FormFeedback>
                                            ) : null}
                                          </div>

                                          <div className="mb-3">
                                            <Label className="form-label">Join Date</Label>
                                            <Input
                                              name="join_date"
                                              type="date"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={validation.values.join_date || (new Date()).toISOString().split("T")[0]}
                                              invalid={
                                                validation.touched.join_date && validation.errors.join_date ? true : false
                                              }
                                            />
                                            {validation.touched.join_date && validation.errors.join_date ? (
                                              <FormFeedback type="invalid">{validation.errors.join_date}</FormFeedback>
                                            ) : null}
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <div className="text-end">
                                            <button type="submit" className="btn btn-success save-contact">
                                              <i className="mdi mdi-account-check me-2" />
                                                Save
                                            </button>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Form>
                                  </ModalBody>
                                </Modal>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Contacts;
