// @flow
import {
    GET_MENU_ITEMS,
  } from "./actionTypes"
  

  const INIT_STATE = {
    menuItems:[{}]
  }
  
  const Menu = (state = INIT_STATE, action) => {
    switch (action.type) {
          case GET_MENU_ITEMS:
            return {
              ...state,
              menuItems: action.payload,
            }       
          
      default:
        return state
    }
  }
  
  export default Menu
  