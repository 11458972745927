import React from "react";
import MetaTags from 'react-meta-tags';
import {RoleListComponent} from "zd-react-authentication";
import {ROLES_API_URL, ROLES_PERMISSIONS_API_URL} from "../../helpers/api-urls";
import { Link } from "react-router-dom";
import AuthorizationChecker from "security/AuthorizationChecker";


const PagesRoles = (props) => {

    return (
    <>
        <div className="page-content">
            <MetaTags>
                <title>Role</title>
            </MetaTags>
                
            <RoleListComponent 
                apiUrl={ROLES_API_URL}
                apiRolePermissionUrl={ROLES_PERMISSIONS_API_URL}
                addUrl="/pages-roles/add"
                path="/pages-roles"
                Link={Link}
                showAdd = { AuthorizationChecker.isGranted(["ADD_ROLES"])}
                showEdit = { AuthorizationChecker.isGranted(["EDIT_ROLES"])}
                showView = { AuthorizationChecker.isGranted(["READ_ROLES"])}
                showDelete  = { AuthorizationChecker.isGranted(["DELETE_ROLES"])}
            />
        </div>
    </>
    )
}

export default PagesRoles
