import React from "react";
import {Link, useParams} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import {PermissionEditComponent} from "zd-react-authentication";
import {PERMISSIONS_API_URL} from "../../helpers/api-urls";
import Breadcrumbs from "../../components/Common/Breadcrumb"

const PagesEditPermissions  = (props) =>{

    const {history} = props;
    const params = useParams();
    const {id = 0} = params;
    const onEditSuccess = (response) => {
        history.push("/pages-permissions");
    }
    return (
        <>
            <div className="page-content">
                <MetaTags>
                    <title>Permission</title>
                </MetaTags>

                <div className="mb-4">
                    <Breadcrumbs title="Permission" breadcrumbItem={"Permission - " + ( id ? "Modification" : "Ajout")}/>
                    <Link className="btn btn-info" to={"/pages-permissions"}>Revoir la liste</Link>
                </div>
                <PermissionEditComponent 
                    id={id}
                    onEditSuccess={onEditSuccess}
                    apiUrl={PERMISSIONS_API_URL}
                    addUrl="/pages-permissions/add"
                    path="/pages-permissions"
                />
            </div>
        </>
    )
}

export default PagesEditPermissions
