import React from "react";
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import {ReferenceEditComponent} from "zd-react-references";
import {REFERENCES_API_URL} from "../../helpers/api-urls";
import Breadcrumbs from "../../components/Common/Breadcrumb"

const PagesEditReferences  = (props) =>{

    const {history, id = 0} = props;
    const baseUrl = "/pages-references"

    const onEditSuccess = (response) => {
        history.push(baseUrl);
    }
    return (
        <>
            <div className="page-content">
                <MetaTags>
                    <title>References</title>
                </MetaTags>

                <div className="mb-4">
                    <Breadcrumbs title="Reference" breadcrumbItem={"References - " + ( id ? "Modification" : "Ajout")}/>
                    <Link className="btn btn-info" to={baseUrl}>Revoir la liste</Link>
                </div>
                <ReferenceEditComponent 
                    id={id}
                    onEditSuccess={onEditSuccess}
                    apiUrl={REFERENCES_API_URL}
                    addUrl={baseUrl+"/add"}
                    path={baseUrl}
                />
            </div>
        </>
    )
}

export default PagesEditReferences
