import axios from "axios";
import {HTTP_RESPONSE} from "../../Utility/Helpers/constants";
import UserProvier from "../../../security/UserProvier";
UserProvier.setToken();

export const DATA_API_URL = process.env.REACT_APP_API_BASE_URL + 'contacts';

const queries = (itemPerPage = 10, page = 1, search = '', exclude=null) => {
    let params = {
        where: {
            and : [
                {
                    or : [
                        { phone_number : { like : '%'+search+'%'}},
                        { username : { like : '%'+search+'%'}},
                        { email : { like : '%'+search+'%'}},
                        { address : { like : '%'+search+'%'}},
                        { priority : { like : '%'+search+'%'}},
                    ]
                }
            ]
        },
        order : 'join_date DESC',
        limit: itemPerPage,
        skip: (((page - 1) * itemPerPage) > 0 ? ((page - 1) * itemPerPage) : 0)
    }

    if (exclude){
        params.where.and.push({
            phone_number : { nin : exclude}
        })
    }
    
    return JSON.stringify(params)
}

const ContactRepository = {
    add : (item) => {
        return axios
            .post(DATA_API_URL, item).then(response => {
                return response.data;
            }).catch((e) => {
                if (e.response) {
                    throw e.response;
                } else {
                    throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
                }
            });
    },
    edit: (item) => {
        return axios
            .put(DATA_API_URL + '/' + item.phone_number, item).then(response => {
                return response.data;
            }).catch((e) => {
                if (e.response) {
                    throw e.response;
                } else {
                    throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
                }
            });
    },
    findAll : (page = 1, search = '', itemPerpage = 10, exclude=null) => {
        return axios
            .get(DATA_API_URL,{
                params:{
                    filter:queries(itemPerpage, page, search,exclude)
                }
            })
            .then(response => {
                return response.data;
            }).catch((e) => {
                if (e.response) {
                    throw e.response;
                } else {
                    throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
                }
            });
    },
    findByPhoneNumber : (phone_number) => {
        return axios
            .get(DATA_API_URL + '/' + phone_number)
            .then(response => {
                return response.data;
            }).catch((e) => {
                if (e.response) {
                    throw e.response;
                } else {
                    throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
                }
            });
    },
    removeByPhoneNumber : (phone_number) => {
        return axios.delete(DATA_API_URL + '/' + phone_number).then((response) => {
            return phone_number
        }).catch((e) => {
            if (e.response) {
                throw e.response;
            } else {
                throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
            }
        });
    }
}
export default ContactRepository;
