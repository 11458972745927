import UserProvier from "../UserProvier";

/**
 * Voter for member both plateforme manager and customer
 */
const UserVoter = {
    /**
     * Determines if the attribute and subject are supported by this voter.
     *
     * @return boolean True if the attribute and subject are supported, false otherwise
     * @param attribute
     * @param subject
     */
     supports: (attribute, subject) => {
        const roles = ["READ_USERS", "ADD_USERS", "DELETE_USERS","EDIT_USERS","ROLE_USER"];
        return roles.includes(attribute);
    },

    /**
     * Perform a single access check operation on a given attribute, subject and token.
     * It is safe to assume that $attribute and $subject already passed the "supports()" method check.
     *
     * @return bool True if the attribute and subject are supported, false otherwise
     * @param attribute
     * @param subject
     * @param token
     */
    voteOnAttribute:(attribute, subject, token)=> {
        let decision = UserVoter.grantOwner(token);
        const roles = UserProvier.roles();
        return decision || roles.includes(attribute);
    },

    /**
     * Perform a single access check operation on a given attribute, subject and token.
     * It is safe to assume that $attribute and $subject already passed the "supports()" method check.
     *
     * @return bool True if the attribute and subject are supported, false otherwise
     * @param token
     */
    grantOwner:(token)=> {
        let decision = false;
        // if (token) {
        //     const access = new Access(jwtDecode(token));
        //     if (access.context_type == CONTEXT_TYPE.RECRUITER) {
        //         decision = access.roles.includes("ROLE_CUSTOMER");
        //     } else if (access.context_type == CONTEXT_TYPE.ADMINISTRATOR) {
        //         decision = access.roles.includes("ROLE_PLATEFORM_MANAGER");
        //     }
        // }
        return decision;
    },
    /**
     * Returns the vote for the given parameters.
     *
     * This method must return one of the following constants:
     * ACCESS_GRANTED, ACCESS_DENIED, or ACCESS_ABSTAIN.
     *
     * @param subject    The subject to secure
     * @param attributes An array of attributes associated with the method being invoked
     * @param token current token to load login user
     *
     * @return int either ACCESS_GRANTED, ACCESS_ABSTAIN, or ACCESS_DENIED
     */
    vote: (token, subject, attributes) => {
        for (let i = 0; i < attributes.length; i++) {
            if (UserVoter.supports(attributes[i], subject)) {
                return UserVoter.voteOnAttribute(attributes[i], subject, token);
            }
        }
        return false;
    }
}
export default UserVoter;
