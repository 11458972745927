/**
 * UserProvider
 * Enable to manage user session and acces authorization.
 *
 * @since      2.0.0
 * @package    App
 * @subpackage App\Core\Authorization
 */
import axios from "axios";

const UserProvier = {
    /**
     * Récupère l'utilisateur courant ou renvoit null si non existant
     */
    get: () => {
        return JSON.parse(localStorage.getItem("authUser"));
    },
    roles: () => {
        const user = UserProvier.get();
        if (user != null){
            if(user.permissions){
                return [...user.permissions, "ROLE_USER"];
            }
        }else{
            return ["ROLE_USER"];
        }
        return [];
    },
    token: () => {
        const user = UserProvier.get();
        if (user != null)
            return user.token;
        return null;
    },
    setToken: () => {
        const token = UserProvier.token();
        if (token != null) {
            axios.defaults.headers['Authorization'] = "Bearer " + token;
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}` 
        } else {
            console.log("Not token found.")
            delete axios.defaults.headers['Authorization']
            delete axios.defaults.headers.common['Authorization']
        }
    },
}
export default UserProvier;
