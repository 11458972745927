import AppVoter from "./voter/AppVoter";
import UserVoter from "./voter/UserVoter";

/**
 * AccessDecisionManager is the base class for all access decision managers
 * that use decision voters.
 * @since      2.0.0
 * @package    App
 * @subpackage App\Core\Authorization
 */

const AccessDecisionManager =  {

    /**
     * Constructor default.
     */
    getVoters: ()=> {
        return [
            AppVoter,
            UserVoter,
        ];
    },

    /**
     * Enable to vote from all voter.
     * @param attributes
     * @param object
     */
    isGranted: (attributes, object)=> {
        const token = "";//EncryptStorage(APP_SECRET).getItem(APP_TOKEN_SECRET_KEY);
        return AccessDecisionManager.decide(token, attributes, object);
    },

    /**
     * Enable to vote from all voter.
     *
     * @param token
     * @param attributes
     * @param object
     */
    decide: (token, attributes, object)=> {
        let decision = false;
        const voters = AccessDecisionManager.getVoters();
        for (let i = 0; i < voters.length; i++) {
            const voter = voters[i];
            decision = voter.vote(token, object, attributes);
            if (decision) {
                break;
            }
        }
        return decision;
    }
}

export default AccessDecisionManager;
