import React from "react";
import {Link} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import {ReferenceViewComponent} from "zd-react-references";
import {REFERENCES_API_URL} from "../../helpers/api-urls";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import AuthorizationChecker from "security/AuthorizationChecker";

const PagesDetailReferences = (props) =>{
    const {id = 0} = props;
    const baseUrl = "/pages-references"

    return ( 
        <>
            <div className="page-content">
                <MetaTags>
                    <title>Reference</title>
                </MetaTags>
                
                <div className="mb-4">
                    <Breadcrumbs title="Reference" breadcrumbItem={"Reference - Détails"}/>
                    <Link className="btn btn-info" to={baseUrl}>Revoir la liste</Link>

                    { AuthorizationChecker.isGranted(["EDIT_REFERENCES"]) && ( <Link className="btn btn-info ms-3" to={baseUrl+"/"+id+"/edit"}>Modifier</Link> )}
                    
                    { AuthorizationChecker.isGranted(["ADD_REFERENCES"]) && (<Link className="btn btn-info ms-3" to={baseUrl+"/add"}>Ajouter</Link> )}
                </div>
                <ReferenceViewComponent
                    id={id}
                    apiUrl={REFERENCES_API_URL}
                    addUrl={baseUrl+"/add"}
                    path={baseUrl}
                />
            </div>
        </>
    );
}

export default PagesDetailReferences
