import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React from "react";
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form } from "reactstrap";

//redux
import {useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";
import DatatableTables from "pages/Tables/DatatableTables";



// Formik Validation

const TodoListPage = props => {
    const dispatch = useDispatch();
  
    return (
      <React.Fragment>
        <MetaTags>
          <title>
            Toto page
          </title>
        </MetaTags>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="fas fa-home h2" />
          </Link>
        </div>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={12}>
                <DatatableTables></DatatableTables>

              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  };
  
  TodoListPage.propTypes = {
    history: PropTypes.object,
  };
  
  export default withRouter(TodoListPage);