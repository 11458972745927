import React from "react";
import {Link, useParams} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import {CompanyViewComponent} from "zd-react-authentication";
import {COMPANY_API_URL,USERS_COMPANY_API_URL} from "../../helpers/api-urls";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import AuthorizationChecker from "security/AuthorizationChecker";

const PagesDetailCompanies = (props) =>{
    const params = useParams();
    const {id = 0} = params;

    return ( 
        <>
            <div className="page-content">
                <MetaTags>
                    <title>Company</title>
                </MetaTags>
                
                <div className="mb-4">
                    <Breadcrumbs title="Company" breadcrumbItem={"Company - Détails"}/>
                    <Link className="btn btn-info" to={"/pages-companies"}>Revoir la liste</Link>

                    { 
                        AuthorizationChecker.isGranted(["EDIT_COMPANIES"]) && 
                        (
                            <Link className="btn btn-info ms-3" to={"/pages-companies/"+id+"/edit"}>Modifier</Link>
                        )
                    }
                    { 
                        AuthorizationChecker.isGranted(["ADD_COMPANIES"]) && 
                        (
                            <Link className="btn btn-info ms-3" to={"/pages-companies/add"}>Ajouter</Link>
                        )
                    }
                </div>
                <CompanyViewComponent 
                    id={id}
                    apiUrl={COMPANY_API_URL}
                    apiUserCompagnyUrl={USERS_COMPANY_API_URL}
                    userPath="/pages-users"
                    Link={Link}
                    showEditAssociatedUser = {AuthorizationChecker.isGranted(["EDIT_USERS"])}
                    showViewAssociatedUser = {AuthorizationChecker.isGranted(["READ_USERS"])}
                    showDeleteAssociatedUser = {AuthorizationChecker.isGranted(["DELETE_USERS"])}
                />
            </div>
        </>
    );
}

export default PagesDetailCompanies
