import React, {useEffect} from "react"
import {useParams} from "react-router-dom";
import {
    Container,
} from "reactstrap"
import MetaTags from 'react-meta-tags';
//Import Breadcrumb
//Import Attachment Cards
import {Link} from "react-router-dom";
import {RegisterComponent} from "zd-react-authentication";
import {USERS_API_URL} from "../../helpers/api-urls";

const PagesUserAdd = (props) => {
    const {history} = props;
    const params = useParams();
    const {id = 0} = params;


    const onEditSuccess = (response) => {
        history.push(getPagesUrl());
    }

    useEffect(() => { 
    }, []);

    const getPagesUrl = () => {
        return '/pages-users';
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Utilisateurs - Edition</title>
                </MetaTags>
                <Container fluid={true}>
                    <div className="mb-4">
                        <Link className="btn btn-info" to={getPagesUrl()}>Revoir la liste</Link>
                    </div>
                    <RegisterComponent 
                        apiUrl={USERS_API_URL}
                        onRegisterSuccess={(u)=>{
                            onEditSuccess();
                        }}
                        
                    />
                </Container>
            </div>
        </React.Fragment>
    )
}
export default PagesUserAdd
